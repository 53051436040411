import React from 'react'
import PropTypes from 'prop-types'
import { Link, navigate } from 'gatsby'
import { readingTime as readingTimeHelper } from '@tryghost/helpers'

const PostCard = ({ post }) => {
    const url = `/${post.slug}/`
    const readingTime = readingTimeHelper(post)

    const handleRouteTag = (e, slug) => {
        e.preventDefault()
        navigate(`/tag/${slug}/`)
    }

    const handleRouteAuthor = (e) => {
        e.preventDefault()
        navigate(`/author/${post.primary_author.slug}/`)
    }

    const TagWithLink = tags => tags.tags.map((tag, index) => <span key={tag.name} onClick={e => handleRouteTag(e, tag.slug)}>{index === 0 ? tag.name : `, ${tag.name}`}</span>)

    return (
        <Link to={url} className="post-card">
            <header className="post-card-header">
                {post.feature_image &&
                    <div className="post-card-image" style={{
                        backgroundImage: `url(${post.feature_image})`,
                    }}></div>}
                {post.tags && (
                    <div className="post-card-tags">
                        <TagWithLink tags={post.tags} />
                    </div>
                )}
                {/* {post.featured && <span>Featured</span>} */}
                <h2 className="post-card-title">{post.title}</h2>
            </header>
            <section className="post-card-excerpt">{post.excerpt}</section>
            <footer className="post-card-footer">
                <div onClick={handleRouteAuthor} className="post-card-footer-left">
                    <div className="post-card-avatar">
                        {post.primary_author.profile_image ?
                            <img className="author-profile-image" src={post.primary_author.profile_image} alt={post.primary_author.name} /> :
                            <img className="default-avatar" src="/images/icons/avatar.svg" alt={post.primary_author.name} />
                        }
                    </div>
                    <span>{post.primary_author.name}</span>
                </div>
                <div className="post-card-footer-right">
                    <div>{readingTime}</div>
                </div>
            </footer>
        </Link>
    )
}

PostCard.propTypes = {
    post: PropTypes.shape({
        slug: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        feature_image: PropTypes.string,
        featured: PropTypes.bool,
        tags: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string,
            })
        ),
        excerpt: PropTypes.string.isRequired,
        primary_author: PropTypes.shape({
            name: PropTypes.string.isRequired,
            profile_image: PropTypes.string,
            slug: PropTypes.string,
        }).isRequired,
    }).isRequired,
}

export default PostCard
